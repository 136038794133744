



































import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class AppComponent extends Vue {
    private drawer = false;
    private menu = [
        { icon: 'mdi-rss', title: 'Blog', link: 'blog' },
        { icon: 'mdi-cloud-download', title: 'Downloads', link: 'root' }
    ];
}
